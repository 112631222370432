.dummyDashboard {
	position: relative;
}

.loading {
	font-size: 1.2rem;
	text-align: center;
	color: darkgrey;
	margin-top: 2rem;
}

.engagement-wrapper,
.inout-wrapper {
	height: 640px;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.inbound-outbound-bars {
	height: 400px;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.map-container {
	height: 480px;
}

.sidebar {
	background-color: rgba(35, 55, 75, 0.9);
	color: #fff;
	padding: 6px 12px;
	font-family: monospace;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	margin: 12px;
	border-radius: 4px;
}

.engagement-wrapper > svg {
	display: block;
	/* background-color: rgba(13, 13, 13, 0.95); */
	font-family: 'Montserrat', sans-serif;
}

.mapboxgl-control-container {
	visibility: hidden;
}

.engagement-wrapper > svg .axis-label {
	font-size: 14px;
	/* font-weight: 500; */
	fill: white;
	opacity: 0.8;
}

.engagement-wrapper > svg .axis {
	color: white;
	opacity: 0.8;

	font-size: 12px;
}

.engagement-wrapper > svg .axis path {
	/* stroke: white; */
	opacity: 0.7;
}

.calendar-heatmap-wrapper {
	width: auto;
	height: 480px;
	margin-left: 200px;
	margin-right: 200px;
}

.calendar-heatmap-wrapper text {
	fill: white;
	opacity: 0.8;
}

.treemap-wrapper,
.donut-wrapper {
	height: 480px;
	width: 480px;
}

.legendEntry > svg {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

.legendEntry {
	margin-right: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.legendDiv {
	display: flex;
	flex-wrap: wrap;
	row-gap: 12px;
	column-gap: 28px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
